:root {
  --bg: #f0f0f0;
  --text-clr: #222222;
  --text-clr-sec: #696c72;
  --bg-light: #fff;
  --bg-sec: #65748c;
  --bg-dark: #2b2c2e;
  --hover-bg-dark: #393a3b;
  --hover-bg-sec: #5b6980;
  --bg-green-status: #23bd51;
  --bg-orange-status: #dfa60b;
  --bg-red-status: #da2f2f;


  --fs-1: 2rem;
  --fs-2: 1.1rem;
  --fs-3: 1rem;
  --fs-6: 13px;
  --fs-auction-1: 1.5rem;
  --fs-auction-2: 1.3rem;
  --fs-auction-3: 1rem;

  --card-ht: 15rem;
  --bid-card-ht: 12rem;
  --header-ht: 4.3rem;
  --footer-ht: 6rem;
}

* {
  box-sizing: border-box !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg) !important;
  padding-bottom: var(--footer-ht);
  max-width: 100vw;
  width: 100vw;
  overflow-x: hidden;
  /* padding-top: 1rem; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiContainer-root {
  padding: 0 !important;
}

.root {}

button:disabled {
  opacity: 0.8;
  transition: opacity 500ms ease-in-out;
}



/* ==========  UTILITY  ============== */
.flex-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.text-center {
  text-align: center !important;
  width: 100%;
  font-weight: 700 !important;
}

.msg {
  font-size: var(--fs-1);
}

.msg.danger {
  color: var(--bg-red-status);
}

.msg.success {
  color: var(--bg-green-status);
}

.link {
  color: rgb(86, 29, 240);
  text-decoration: underline;
  cursor: pointer;
  transition: all 100ms linear;
}

.link:hover {
  color: rgb(90, 72, 139);
}

.section-title {
  font-size: var(--fs-1) !important;
  text-align: center;
}

.error-msg {
  color: red;
  padding: 0 5vw;
  font-size: var(--fs-3);
  text-align: center;
  /* width: 100%; */
}

.success-msg {
  color: var(--bg-green-status);
  padding: 0 5vw;
  font-size: var(--fs-3);
  text-align: center;
  /* width: 100%; */
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem !important;
  height: 2.5rem !important;
  border: none !important;
  border-radius: 10px !important;
  margin-top: 1rem !important;
  cursor: pointer;
  transition: all 150ms linear;
  position: relative;
  margin-right: 2rem;
  text-decoration: none;
}

.btn:disabled {
  opacity: 0.8;
  cursor: not-allowed;
}

.danger-btn {
  background-color: rgb(223, 23, 23);
  color: var(--bg-light);
}

.danger-btn:hover {
  background-color: rgb(238, 31, 31);
}

.primary-btn {
  background-color: var(--bg-dark) !important;
  color: var(--bg-light) !important;
}

.primary-btn:hover {
  background-color: var(--hover-bg-dark) !important;
}

.primary-btn:disabled {
  opacity: 0.7;
}

.light-btn {
  background-color: var(--bg-sec) !important;
  color: var(--bg-light) !important;
}

.light-btn:hover {
  background-color: var(--hover-bg-sec) !important;
}

.light-btn-ghost {
  background-color: transparent !important;
  color: var(--bg-sec) !important;
  border: 1px solid var(--bg-sec) !important;
  transition: all 150ms linear;
}

.light-btn-ghost:hover {
  background-color: var(--bg-sec) !important;
  color: var(--bg-light) !important;
}

.btn.csv-btn {
  margin-right: 1rem;
  margin-top: 0 !important;
  border-radius: 5px !important;
  height: 3rem !important;
  background-color: var(--bg-dark);
  border: 1px solid rgb(175, 175, 175) !important;
  color: white;
  text-transform: none;
  width: auto !important;
  min-width: 10rem !important;
  padding: 0 1.5rem !important;
  position: relative;
}

.btn.csv-btn:hover {
  background-color: var(--hover-bg-dark);
  color: white;
}

.remove-file-icon {
  position: absolute;
  color: red;
  z-index: 4;
  cursor: pointer;
  left: 25px;
  bottom: -30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-file-icon:hover {
  text-decoration: underline;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-container {
  /* gap: 2rem; */
  display: flex;
  align-items: center;
}

.btn-container.centered {
  justify-content: center;
}

.btn input {
  border: solid greenyellow;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.btn-pill {
  border-radius: 20px;
}

.tab-container {
  position: relative;
  background-color: transparent !important;
}

.tabs {
  width: auto;
  position: absolute;
  right: 0;
  top: -5.8rem;
  width: 40%;
  border: solid;
  border-radius: 40px;
  padding: 4px;
  border: 0.6px solid rgba(34, 34, 34, 0.541) !important;
}

.MuiDataGrid-toolbarContainer {
  position: absolute;
  top: -3.6rem;
  right: 0;
  width: 8rem;
  border: 0.6px solid rgba(34, 34, 34, 0.541) !important;
  border-radius: 20px;
  transition: all 150ms linear;
}

.MuiDataGrid-toolbarContainer:hover {
  border-color: var(--bg-sec) !important;
  background-color: var(--bg-sec) !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  color: var(--bg-sec) !important;
}

.css-1knaqv7-MuiButtonBase-root-MuiButton-root:hover {
  color: white !important;
}

.MuiDataGrid-toolbarContainer span {
  display: none;
}

.tab {
  width: 50% !important;
  border: none !important;
  outline: none !important;
  border-radius: 40px !important;
  color: var(--bg-sec) !important;
  font-weight: 600 !important;
  text-transform: capitalize !important;
}

.tab.Mui-selected {
  background-color: var(--bg-sec) !important;
  color: var(--bg-light) !important;
}

.MuiTabs-indicator {
  display: none;
}

.MuiPaper-root {
  /* background-color: transparent !important; */
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.MuiDataGrid-root {
  border: none !important;
  box-shadow: none !important;
}

.MuiDataGrid-main {
  background-color: var(--bg-light) !important;
  border-radius: 10px !important;
  box-shadow: none !important;
}

.MuiDataGrid-columnHeaders {
  background-color: var(--bg-sec) !important;
  color: var(--bg-light) !important;
  font-weight: 600 !important;
}

.header-right-aligned {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.header-right-aligned button {
  margin: 0 !important;
}

.header-flexed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}


.btn-top-right {
  position: absolute !important;
  top: 2rem;
  right: 1rem;
}

.file-upload-btn {
  margin-bottom: 1rem !important;

}

.flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  position: relative;
}

.flex-column {
  align-items: flex-start;

}

.back-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  position: absolute;
  top: 3.4rem;
  left: 2.4rem;
  border: 1px solid var(--bg-sec);
  color: var(--bg-sec);
  padding: 0.3rem .5rem;
  border-radius: 20px;
  background-color: var(--bg);
  padding: .35rem 2rem;
  z-index: 1;
}

.back-btn:hover {
  color: var(--bg-dark);
  border-color: var(--bg-dark);
}

.back-btn:hover svg {
  transform: translateX(-2px);
}

.back-btn svg {
  width: 20px;
}

.back-btn-2 {
  margin-top: .5rem;
  /* margin-left: 5vw; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #02020270;
}

.spinner-icon {
  font-size: 18px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ============== HEADER  ============== */
header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 4vw;
  height: var(--header-ht);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  padding-left: 3rem !important;
}

.menu-icon {
  background-color: var(--bg-dark);
  width: 43px;
  height: 43px;
  padding: 8px;
  border-radius: 5px;
  color: var(--bg-light);
  cursor: pointer;
}




/* ===========  MENU  ============== */

.nav-logo {
  width: 6rem;
}

.nav-link-container {
  display: flex;
  gap: 4rem;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
}

.nav-link {
  color: rgb(168, 168, 168);
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-size: 15px;
}

.nav-link.active {
  color: var(--bg-light);
}

.nav-link .link-icon {
  font-size: calc(var(--fs-3) + 6px);
}

.nav-link.dark {
  color: #222;
}

.menu-container {
  width: 20rem;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0%;
  background-color: rgb(255, 255, 255);
  z-index: 5 !important;
}

.menu-container::before {
  position: absolute;
  content: '';
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.144);
  top: 0;
  right: 100%;
}

.menu-container.menu-hidden {
  right: -170%;
}

.menu-close-icon {
  position: absolute;
  top: 1.5rem;
  right: 1.85rem;
  font-size: 1.5rem;
  cursor: pointer;
}

.menu-close-icon:hover {
  transform: translateY(-1px);
  transition: all 100ms linear;
}

.menu-link-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  margin-top: 8rem;
  width: 100%;
}

.menu-link-container a {
  border-bottom: 1px solid rgb(221, 221, 221);
  text-decoration: none;
  height: 4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 2rem;
  color: var(--text-clr);
}

.menu-link-container a:hover {
  color: var(--text-clr-sec);
  transition: color 100ms linear;
}


header.admin-nav {
  background-color: var(--bg-dark) !important;
}

/* ==========  LOGIN   ============ */
.login-container {
  display: flex !important;
  height: calc(100vh - var(--footer-ht));
  width: 100vw !important;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  margin: auto !important;
}

.title {
  font-size: var(--fs-1);
  margin: 0;
  padding: 0;
}

.subtitle {
  color: var(--text-clr-sec);
  margin-top: 0;
  font-size: var(--fs-2);
  text-align: center;
  margin: 0 4vw;
}

.logo {
  width: 7rem;
  margin: 2rem 0;
}

.login-form {
  display: flex;
  flex-direction: column !important;
}

.login-form input {
  width: 18rem;
  height: 3.5rem !important;
  font-size: var(--fs-3) !important;
}

.login-form label {
  font-size: var(--fs-3) !important;

}

.login-form button {
  width: 19.8rem;
  height: 3.5rem;
  border-radius: 5px !important;
  margin-top: 2rem;
  border: none;
  color: var(--bg-light);
  background-color: var(--bg-dark);
  cursor: pointer;
  font-size: var(--fs-4) !important;
}

.login-form button:hover {
  background-color: var(--hover-bg-dark);
  transition: background-color 150ms linear;
}





/* ===========  CAR CARD  ============== */
.legend {
  display: flex;
  /* flex-direction: column; */
  gap: .8rem;
  font-size: var(--fs-4);
  padding: 1rem;
  border-radius: 10px;
  background: rgb(230, 230, 230);
}

.legend .item {
  display: flex;
  gap: 0.2rem;
  height: 1.8rem;
  align-items: center;
}

.legend .item span {
  width: 1rem;
  height: .9rem;
  border-radius: 4px;
  background: var(--bg-green-status);
}

.item.orange span {
  background-color: var(--bg-orange-status);
}

.item.red span {
  background-color: var(--bg-red-status);
}






/* ===========  CAR CARD  ============== */
.car-list-container {
  padding: 0 4vw;
  /* padding-top: 2rem; */
  display: flex;
  gap: 1.5rem;
  padding-bottom: 3rem;
}

.car-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--card-ht);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px !important;
  width: 100%;
  background-color: var(--bg-light);
  gap: 2vw;
  cursor: pointer;
  padding-right: 10px;
}

.car-card .car-img {
  width: 40%;
  height: 85%;
  /* max-height: 7rem; */
  margin: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.car-data-container {
  width: 60% !important;
  text-align: left;
}

.model {
  font-size: var(--fs-4) !important;
  font-weight: 700 !important;
  margin-top: 10px !important;
}

.reg-number {
  color: var(--text-clr-sec);
  font-size: var(--fs-5) !important;

}

.value-container {
  display: flex;
  margin-top: 1.2rem;
}

.value {
  width: 50%;
}

.value-title {
  font-size: var(--fs-5) !important;
  color: var(--text-clr-sec) !important;

}

.value-data {
  font-size: var(--fs-5) !important;
  font-weight: 700 !important;
}

.bid-value {
  font-size: var(--fs-5) !important;
  font-weight: 600 !important;
  margin-top: 0.5rem !important;
}

.car-card-header {
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
  gap: 10px;
}

.bid-type {
  background-color: var(--bg-dark);
  padding: 8px;
  border-radius: 5px;
  color: var(--bg-light);
  font-size: var(--fs-6) !important;
}

.bid-type.green {
  background-color: var(--bg-green-status);
}

.bid-type.orange {
  background-color: var(--bg-orange-status);
}

.bid-type.red {
  background-color: var(--bg-red-status);
}

.bid-status-icon {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 0 1rem;
  border: 2px solid white;
}

.bid-status-icon.green {
  background-color: var(--bg-green-status) !important;
  outline: 1px solid var(--bg-green-status) !important;
}

.bid-status-icon.red {
  background-color: var(--bg-red-status);
  outline: 1px solid var(--bg-red-status);
}

.bid-status-icon.orange {
  background-color: var(--bg-orange-status);
  outline: 1px solid var(--bg-orange-status);
}

.car-card-footer {
  display: flex;
  gap: 20px;
}

.bid-value {
  position: relative;
  margin-left: 15px;
}

.bid-value::before {
  content: '';
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 50%;
  background: var(--bg-dark);
  border-radius: 3px;
}

.bid-value.green::before {
  background-color: var(--bg-green-status);
}

.bid-value.orange::before {
  background-color: var(--bg-orange-status);
}

.bid-value.red::before {
  background-color: var(--bg-red-status);
}

.table-row .bid-value {
  font-size: var(--fs-3) !important;
}

.bidding-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
}

.bidding-list-card {
  width: 100%;
  /* flex-direction: column; */
  height: 85px;
  padding: 0;
  gap: 0.5rem;
  justify-content: flex-start;
}

.bidding-list-card .car-img {
  width: 95%;
  height: 90%;
  margin-top: 0;
  margin: 10px;
}

.bidding-list-card .car-data-container {
  padding: 0;
  width: 100% !important;
  margin-top: 0;
  padding: 0 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}

.bidding-list-card .car-card-header {
  width: 25%;
  /* border: solid; */
  height: 100%;
  margin: 0;
}

.bidding-list-card .car-card-body {
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 50%;
}

.bidding-list-card .car-card-footer {
  width: 25%;
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  justify-self: flex-end;
}

.car-card-footer-sm {
  display: none;
}

.bidding-list-card .bid-type {
  padding: 8px;
  border-radius: 5px;
  color: var(--bg-light);
  /* font-size: 14px !important; */
}


/* ==========  CAR DETAILS PAGE  ================= */
.view-car-container {
  border: solid !important;
  padding: 0;
}

.css-jcr0l6 {
  margin-top: calc((var(--header-ht) + 10px) * -1);
}

.swiper-header {
  background-color: transparent !important;
}

.left-arrow,
.right-arrow {
  position: absolute !important;
  top: -13rem;
  width: 2rem !important;
  height: 2rem !important;
}

.left-arrow {
  left: -1vw;
}

.right-arrow {
  right: -1vw;
}

.pagination-container {
  /* border: solid; */
  padding-top: 1rem !important;
  background-color: transparent !important;
}

.car-details-container {
  padding: 3vh 6vw;
  padding-top: 1rem;
}

.car-details-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.car-details-header .left {
  margin-top: 2rem;
  display: flex;
  width: 3rem;
}

.car-details-header .right {
  width: 85%;
}

.car-title {
  color: var(--text-clr);
  text-align: left;
  font-size: var(--fs-1);
  margin-bottom: 0;
}

.car-subtitle {
  text-align: left;
  color: var(--text-clr-sec);
  font-size: var(--fs-2);
  margin-top: 10px;
  /* margin-bottom: 3rem; */
}

.car-detail-table {
  margin-top: 3rem;
}

.car-info-table {
  border: 0.3px solid rgb(221, 221, 221);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
}

.car-info-table h3 {
  font-size: var(--fs-2);
}

.car-info-table .table-row {
  display: flex;
  gap: 10px;
}


.table-data-title {
  width: 40%;
  text-align: left;
  font-weight: 500;
  color: var(--text-clr-sec);
  font-size: var(--fs-3) !important;
}

.table-data {
  width: 60%;
  text-align: right;
  font-weight: 500;
  color: var(--text-clr);
  font-size: var(--fs-3) !important;

}

.table-row.remark {
  flex-direction: column;
}

.table-data.remark {
  border: 0.3px solid rgb(221, 221, 221);
  width: 100%;
  text-align: left;
  padding: 1rem;
  border-radius: 10px;
  min-height: 5rem;
}


/* history */
.car-bid-history-container {
  border: 0.3px solid rgb(221, 221, 221);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border-radius: 10px;
  margin: 3vh 6vw;
}

.car-bid-history-container .table-title {
  text-align: left;
  font-size: var(--fs-2);
}

.car-bid-history-container td {
  text-align: left;
  color: var(--text-clr-sec);
  font-size: var(--fs-3);
}

.car-bid-history-container th {
  text-align: left;
  /* padding-bottom: 0.7rem; */
  font-size: var(--fs-3);
}

.hr {
  border-top: 0.3px solid rgb(221, 221, 221);
  width: 100%;
}

.cancel-btn {
  width: 1.7rem;
  height: 1.7rem;
  border: none;
  margin: .4rem 0;
  cursor: pointer;
}

.cancel-btn:hover>.cancel-icon {
  transition: all 100ms linear;
  background-color: rgba(248, 185, 185, 0.411);

}

.cancel-icon {
  width: 1.7rem;
  height: 1.7rem;
  color: rgb(243, 19, 19) !important;
  background-color: rgba(247, 213, 213, 0.411);
  padding: 5px;
  cursor: pointer;
  display: flex;
  /* margin: .4rem 0; */
  margin-left: -7px;
  margin-top: -3px;
  pointer-events: none;
}


/* Modals */
.car-list-email-modal .MuiBox-root {
  border: none;
  width: 30rem;
  width: 90%;
  height: 90vh;
  overflow-y: scroll;
}

.confirmation-modal .MuiBox-root,
.award-modal .MuiBox-root {
  background-color: var(--bg-light);
  width: 40rem !important;
  max-width: 90vw;
  border-radius: 10px;
}



.view-car-modal .MuiBox-root {
  border: none;
  width: 90%;
  max-width: 30rem;
}

/* //bidding form */
.bidding-form-container {
  background-color: var(--bg-dark);
  width: 100% !important;
  /* margin-left: -3.3vw; */
  padding: 2rem 5vw;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.radio-container {
  border: 1px solid var(--bg-light) !important;
  border-radius: 10rem;
  width: 95%;
  height: 4rem;
  justify-content: space-between;

}

.radio-grp {
  width: 100%;
  height: 100%;
  border-radius: 10rem;
  display: flex;
  justify-content: space-between;
  padding: 6px;
}

.radio-grp label {
  width: 50%;
  margin: 0;
  position: relative
}


.MuiButtonBase-root {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 10rem !important;
  background-color: var(--bg-dark);
  z-index: 0;
  /* opacity: 0; */
}


.radio-grp label .MuiTypography-root {
  position: absolute;
  z-index: 1;
  top: 30%;
  left: 50%;
  transform: translate(-50%);
  color: var(--bg-light);
  font-weight: 600 !important;
  font-size: var(--fs-4) !important;
}

/* selects radio button circle */
.MuiButtonBase-root span {
  opacity: 0 !important;
}

.Mui-checked {
  background-color: var(--bg-light) !important;
  /* -webkit-transition: background-color 1000ms linear;
  -ms-transition: background-color 1000ms linear; */
  transition: background-color 200ms ease-in-out;
}

.Mui-checked+.MuiTypography-root {
  color: var(--text-clr) !important;
  font-size: var(--fs-3) !important;
}

.bid-status-container {
  width: 100%;
  color: white;
  height: 3rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bid-status-container span {
  font-weight: 600;
}

.bid-status-container.green {
  background-color: var(--bg-green-status);
}

.bid-status-container.orange {
  background-color: var(--bg-orange-status);
}

.bid-status-container.red {
  background-color: var(--bg-red-status);
}

.bid-form {
  width: 100%;
  display: flex;
  gap: 2vw;
  height: 3.8rem !important;
}

.bid-input-container {
  width: 60%;
  position: relative;
  padding: 0;
}

.bid-input {
  width: 100%;
}

.bid-input-container .bid-input input {
  margin: 0;
  width: 180% !important;
  height: 3.5rem;
  background-color: rgba(255, 255, 255, 0.342);
  border: none !important;
  border-radius: 10px;
  padding-left: 2.2rem;
  z-index: 1;
  color: var(--bg-light);
  /* caret-color: var(--bg-light); */
}

.bid-input-container .bid-input input::selection {
  color: transparent;
}

.bid-input-container .bid-input input::-webkit-outer-spin-button,
.bid-input-container .bid-input input::-webkit-inner-spin-button {
  display: none;
}

.bid-input-container .bid-input input:focus {
  border: none !important;
  outline: none !important;
}

.bid-input-container .bid-input:focus {
  border: none !important;
  outline: none !important;
}

.thousand-seperated-bidAmount {
  width: 100%;
  height: 3.5rem;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.342);
  padding-left: 2.2rem;
  color: var(--bg-light);
  font-size: var(--fs-3);
  border: 0;
  z-index: 0;
  border-radius: 10px;
  pointer-events: none;
}

.dollor-icon {
  position: absolute;
  color: var(--bg-light);
  left: 1.3rem;
}

.currency {
  position: absolute;
  color: var(--bg-light);
  top: 20%;
  transform: translateY(-50%);
  right: 1.3rem;
}

.bid-form button {
  width: 40%;
  height: 3.5rem;
  background-color: var(--bg-light);
  border-radius: 10px !important;
  color: var(--bg-dark);
  font-weight: 600;
  text-transform: capitalize;
  font-size: var(--fs-4);
}

.bid-form button:hover {
  background-color: #e9e5e5;
  transition: all 100ms linear;
}

.terms {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--bg-dark);
}

#terms-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background: var(--bg-light);
  color: black;
  appearance: none;
  border: 1px solid var(--bg-dark);
  position: relative;
  border-radius: 3px;
}

#terms-checkbox:checked::after {
  content: '\2713';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: var(--bg-dark);
  padding-left: 3px;
  transition: all 150ms linear;
}



/* confirmation drawer */
.confirmation-drawer .MuiDrawer-paper {
  border-radius: 20px 20px 0 0;
  padding: 2rem 5vw !important;
}

.confirmation-drawer .notch {
  width: 5rem;
  height: 0.4rem;
  background-color: var(--bg-dark);
  opacity: 0.8;
  margin: auto;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.confirmation-drawer h3 {
  margin: 0;
  margin-top: 1rem;
}

.confirmation-drawer h4 {
  margin: 0;
  color: var(--bg-sec);
}

.confirmation-drawer .terms-list {
  border: 1px solid rgb(177, 177, 177);
  width: 100%;
  height: 10rem;
  padding: 1rem;
  overflow-y: scroll;
  border-radius: 10px;
  color: var(--text-clr-sec);
}

.confirmation-drawer .terms {
  justify-content: flex-start;
}

/* -------  Bid Success Modal  ------- */
.bid-success-container {
  width: 100%;
  background-color: var(--bg);
}

.bid-success-container h2 {
  text-align: center;
}

.bid-success-container .full-width-img {
  display: flex;
  margin: auto;
}

.full-width-img {
  width: 85%;
  max-width: 30rem;
  border-radius: 10px;
}

.bid-success-container .car-title,
.bid-success-container .car-subtitle {
  text-align: center;
}

.bid-success-data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 1rem 0;
  align-items: center;
}

.bid-success-data .data-item {
  color: var(--text-clr-sec);
}

.bid-success-data .data-value {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
}

.success {
  color: #06b649;
}

.success svg {
  border: 1px solid;
  border-radius: 100%;
  padding: 2px;
}

.full-width-button {
  display: flex;
  width: 90%;
  border: 1px solid var(--bg-dark);
  margin: auto;
  justify-content: center;
  align-items: center;
  height: 3rem;
  margin-bottom: 3rem;
  border-radius: 10px;
  /* border: none; */
  text-decoration: none;
  color: var(--text-clr);
  background-color: transparent;
}

.full-width-button:hover {
  background-color: #383838;
  color: var(--bg-light);
  transition: all 250ms ease-in-out;
}



/* ================  BID MANAGEMENT  ============== */

.upload-car-modal .MuiBox-root {
  width: 90vw;
  border: none;
  position: relative;
  padding-top: 3rem !important;
}

.email-content-textarea {
  width: 100%;
  min-height: 16rem;
  font-size: var(--fs-3);
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  border-radius: 8px;
}



/* ============  Biddings list page ================ */





/* ================  VIEW BID PAGE  ============== */
.page-header-img {
  width: 100%;
  height: 18rem;
  background-size: cover;
  margin-top: calc((var(--header-ht) + 10px) * -1);
}




/* ================  ALL BIDS  ============== */
.all-bids-data-table {
  height: 70vh !important;
}

.all-bids-data-table .MuiDataGrid-toolbarContainer {
  border: none !important;
  color: #222 !important;
  background-color: transparent !important;
  position: absolute;
  right: 14rem;
  width: 20rem;
  top: -52px;
}

.all-bids-data-table .MuiDataGrid-toolbarContainer:focus-within {
  background-color: transparent !important;
}

.all-bids-data-table .MuiDataGrid-toolbarContainer:hover {
  background-color: transparent !important;
}

.MuiDataGrid-toolbarContainer .MuiButtonBase-root[aria-label='Export'] {
  border: solid green !important;
  display: none;
}


.all-bids-data-table .MuiDataGrid-toolbarContainer .MuiInputBase-input {
  /* border: solid red; */
  width: 100% !important;
  color: #222 !important;
}

.all-bids-data-table .MuiDataGrid-toolbarContainer button[aria-label='Clear'] {
  color: #222 !important;
  fill: #222 !important;
  width: 10px !important;
}

.table-container {
  width: 100%;
  overflow-x: scroll;
}

.all-bids-table {
  background-color: var(--bg-light);
  padding: 0 1rem;
  border-collapse: collapse;
  min-height: 550px;
}

.all-bids-table thead {
  background-color: var(--bg-sec);
  color: var(--bg-light);
  border-radius: 10px 10px 0 0;
}

.all-bids-table thead span.sr-only {
  display: none;
}

.all-bids-table thead .text-filter {
  height: 1.5rem;
  width: 9rem;
  margin-top: .5rem;
}

.all-bids-table thead th {
  padding: 0.7rem;
  font-weight: normal;
  font-size: 15px;
}

.all-bids-table th:first-child {
  border-top-left-radius: 10px;
  border: 1px solid transparent;
}

.all-bids-table th:last-child {
  border-top-right-radius: 10px;
  border: 1px solid transparent;
}

.all-bids-table td {
  overflow-x: hidden;
  font-size: 15px;
  text-align: center;
}

.all-bids-table td:first-child {
  padding-left: 0.7rem;
}






/* ================  ECAR GALLERY  ============== */
.delete-img-box:hover .overlay {
  background-color: #ff000042;
  transition: all 100ms linear;
}




/* ================  EDIT CAR MODAL  ============== */
.edit-car-modal .button-container {
  width: 100%;
}

.edit-car-modal .MuiBox-root {
  border: none;
}



/* ============  VIEW CAR LIST  ============ */
.car-gallery-modal .MuiBox-root {
  border: none;
}

.car-action-btn {
  width: 30%;
}

.edit-car-form {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  flex-direction: row !important;
}

.edit-car-form input {
  height: 3.6rem !important;
}

.edit-car-form .date-input {
  position: relative;
  width: 14rem;
}

.edit-car-form .date-input label {
  position: absolute;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.507);
  top: 7px;
  left: 10px;
  background-color: white;
  padding: 0 4px;
}

.edit-car-form input[type='date'] {
  margin-top: 16px;
  margin-bottom: 8px;
  width: 100%;
  font-size: 1.1rem;
  border: 1px solid #afafaf;
  border-radius: 5px;
  padding: 0 .6rem;
}

.edit-car-form input[type='date']:hover {
  border-color: #222;
}

.edit-car-form input[type='date']:focus {
  outline: none;
  border: 2px solid rgb(0, 140, 255);
}

.edit-car-form input[type='date']:focus+label {
  color: rgb(0, 140, 255);
}

.new-img-container {
  display: flex !important;
  flex-direction: row !important;
}

.selected-img {
  background-image: url('https://endlessicons.com/wp-content/uploads/2012/11/image-holder-icon-614x460.png');
  width: 60%;
  height: 20vw;
  background-size: cover !important;
  background-position: center;
  padding: 1rem;
  border: 1px solid rgba(83, 79, 79);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.selected-img svg {
  font-size: calc(var(--fs-1) * 3);
  color: rgb(83, 79, 79);
}

.new-img-container .button-container {
  padding: 1rem;
  width: 40%;
  height: auto;
  align-items: flex-end;
}

.car-gallery-modal .main {
  width: 80%;
  height: 80vh;
  overflow-y: scroll;
}

.car-pos-container {
  flex-wrap: wrap;
}

.car-pos {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.car-pos-img {
  width: 20rem;
  height: 15rem;
  background-size: cover;
  background-position: center;
}

/* ============  SESSION FORM  ================ */
.session-form {
  padding: 3vw;
  padding-top: 2rem;
}

.input-grp-container {
  display: flex;
  width: 100%;
  gap: 2rem;
  margin-top: 1rem;
}

.input-grp-container .input-grp {
  width: 40%;
}

.input-grp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.input-grp-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 10%;
  padding-top: 1rem;
}

.input-grp label {
  color: var(--text-clr-sec);
}

.input-grp input {
  height: 2.5rem;
  border-radius: 5px;
  border: 1px solid gray;
  width: 100%;
}

.session-btn {
  width: 70% !important;
  margin-top: 3rem !important;
}





/* ============  Select winner  ============== */
.select-winner-header {
  display: flex;
  flex-direction: column;

}

.select-winner-header .seperator {
  width: 1px;
  height: 1.5rem;
  background-color: #222222;
}

.select-winner-header .top {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: -0.3rem;
}

.select-winner-header .top h2 {
  font-weight: normal;
  margin: 0;
}


.select-winner-header .bottom {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
}

.select-winner-header .bottom .seperator {
  width: 1px;
  height: 1rem;
  background-color: #222222;
}



/* ============  AWARD LETTER  ============== */
.award-modal .MuiBox-root {
  background-color: var(--bg-light);
  width: 60rem !important;
  height: 93vh !important;
  max-width: 90vw;
  border-radius: 10px;
}

.award-preview,
.letter-preview {
  width: 100%;
  height: 78%;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.award-canvas {
  background-color: #fff;
}

#award-canvas {
  /* width: 100%;
  padding: .5rem 2rem; */

}

#award-canvas-final{
  display: none;
}

.award-container {
  /* border: 1px solid var(--bg-dark);
  border-radius: 10px;
  padding: 4rem;
  margin: 2rem 0;
  color: rgb(26, 25, 25); */
}

.award-container img {
  /* width: 8rem;
  height: auto; */
}

.award-container div {
  /* display: flex;
  flex-direction: column;
  width: 100%; */
}

.award-container .left {
  /* align-items: flex-start;
  font-weight: bold; */
}

.award-container .right {
  /* align-items: flex-end; */
}

.award-container .center {
  /* align-items: center;
  margin: 2rem 0; */
}

.award-container .data-container {
  /* display: flex;
  flex-direction: row;
  margin: 1rem 0; */
}

.award-container .data-title {
  /* width: 40%;
  text-align: right;
  font-weight: bold; */
}

.award-container .seperator {
  /* width: 20%;
  text-align: center;
  font-weight: bold; */
}

.award-container .data {
  /* width: 40%;
  text-align: left; */
}

.award-container .normal {
  /* margin: 2rem 0; */
}

.bullets-container {
  /* border: 1px solid var(--bg-dark);
  border-radius: 10px;
  padding: 2rem;
  font-size: var(--fs-3);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  overflow: hidden;
  resize: none; */
}

.bullets-container span {
  /* display: flex; */
}

.bullets-container .points {
  /* width: 5%; */
}

.bullets-container .text {
  /* width: 95%; */
}




/* ============  HANDOVER FORM  ============== */
.handover-form-container {
  width: 794px;
  height: 50px;
  /* height low because print command was taking two pages this fixed that */
  background: white;
  padding: 3rem;
}

.handover-form-container h2 {
  font-size: 25px;
  color: rgb(43, 43, 43);
  text-transform: capitalize;
  margin-top: 0;
  margin-bottom: 10px;
}

.handover-form-container h3 {
  font-size: 18px;
  color: rgb(66, 66, 66);
  text-transform: capitalize;
  margin-top: 5px;
}

.handover-form-container .right {
  text-align: right;
}

.handover-form-container img {
  width: 7rem;
}

.info-grp {
  display: flex;
  gap: 3rem;
}

.info-grp .info {
  width: 50%;
}

.info {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.info.full {
  flex-direction: column;
}

.info.full .info-title {
  margin-bottom: 1rem;
}

.info.bullet {
  margin-left: 4rem;
  position: relative;
}

.info.bullet::before {
  content: '';
  position: absolute;
  width: 9px;
  height: 9px;
  background-color: rgb(58, 58, 58);
  border-radius: 100%;
  top: 50%;
  transform: translateY(-50%);
  left: -1.5rem;
}

.info-data {
  border-bottom: 1px solid gray;
  min-width: 40%;
}

.info span {
  font-size: 15px;
}

.notice {
  font-size: 15px;
  margin-bottom: 1rem;
}

.bank-details {
  margin-top: 1rem;
}

.bank-details h4 {
  font-size: 15px;
  text-decoration: underline;
  margin: 0;
}

.bank-details p {
  font-size: 15px;
  margin: 0;
}

.payment-modes {
  font-size: 16px !important;
  margin-bottom: 0.5rem !important;
  margin-top: 2rem !important;
}







/* ============  NEW DEALER  ============== */
.form {
  border-radius: 10px;
  background-color: var(--bg-light);
  padding: 3rem;
  width: 100% !important;
}

.form h1 {
  text-align: left;
  width: 100%;
}

.form input {
  width: 100%;
  height: 3.3rem;
}

.text-hidden input {
  color: transparent !important;
}

.form .MuiGrid-root .MuiGrid-root {
  width: 50%;
  margin-bottom: 1rem;
}

.form .MuiFormControl-root {
  width: 100%;
}

.form .full.MuiGrid-root {
  width: 100%;
  border: none !important;
}

.company-select {
  width: 100% !important;
}

.company-select .MuiOutlinedInput-root {
  padding: 0 !important;
  padding-right: 2rem !important;
}

.form .submit-btn {
  border: 1px solid var(--bg-sec);
  color: var(--bg-sec);
  height: 3rem;
  width: 50%;
  text-align: left;
  margin: 2rem auto;
  margin-top: 3rem;
}

.form button:hover {
  background-color: var(--bg-sec);
  color: var(--bg-light);
  border-color: var(--bg-sec);
}


/* companies modal */

.manage-companies-btn {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(0, 43, 160);
  margin-top: 3px;
}

.companies-container {
  padding: 2rem 0 4rem 1rem;
  height: 35rem;
  overflow-y: scroll;
}

.companies-container .company {
  margin: 1rem 0;
  margin-bottom: 2rem;
}

.company input {
  width: 60%;
  height: 3rem;
  font-size: var(--fs-4);
  border-radius: 5px;
  border: 1px solid gray;
  padding-left: 0.5rem;
}

.company button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 5px;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 0.7rem;
}

.company-delete-btn {
  color: white;
  background-color: rgb(224, 7, 7);
}

.company-delete-btn:hover {
  background-color: rgb(236, 9, 9);
  transition: background-color 100ms linear;
}

.company-update-btn {
  color: white;
  background-color: var(--bg-sec);
}

.company-update-btn:hover {
  background-color: var(--hover-bg-sec);
  transition: background-color 100ms linear;
}

.companies-container .company .top {
  display: flex;
  gap: 1rem;

}

.companies-container .company-error {
  color: red;
  font-size: var(--fs-6);
}





/* ============  AUDITS  ============== */
.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
  align-items: flex-start;
  overflow: hidden;
}

.failed {
  color: red;
  background-color: rgb(255, 239, 239);
}

.MuiDataGrid-root .failed.MuiDataGrid-row:hover {
  background-color: rgba(255, 239, 239, 0.699);
}






/* ============  FOOTER  ============== */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: var(--footer-ht) !important;
  background-color: var(--bg-dark);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15vw;
  z-index: 4;
}

footer a {
  display: flex;
  gap: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: rgb(155, 154, 154);
  font-size: var(--fs-5);
}

footer a.active {
  color: white;
}

footer a svg {
  font-size: 1.5rem;
}



@media only screen and (max-width: 600px) {
  :root {
    --fs-1: 23px;
    --fs-2: 18px;
    --fs-3: 15px;
    --fs-4: 13px;
    --fs-5: 12px;
    --fs-6: 11px;

    --footer-ht: 4.8rem;

    --fs-auction-1: 15px;
    --fs-auction-2: 15px;
    --fs-auction-3: 15px;

    --card-ht: 10rem;
  }

  body {
    padding-bottom: 5rem !important;
  }

  header .MuiToolbar-root{
    padding: 0 2rem;
  }

  .car-card .car-img {
    /* max-height: 7rem; */
  }


  /* .bidding-list-card {
    width: 100%;
    height: 330px;
  } */

  .bidding-list-card {
    height: 100px;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
  }

  .bidding-list-card .car-data-container {
    flex-direction: row;
    gap: 0.2rem;
  }

  .bidding-list-card .car-card-header {
    width: 100%;
    display: none;
  }

  .bidding-list-card .car-card-body {
    width: 60%;
  }

  .bidding-list-card .car-card-footer {
    display: none;
  }

  .bidding-list-card .car-card-footer-sm {
    display: flex;
    width: 50%;
    flex-direction: column;
    /* gap: .1rem; */
  }

  .car-card-footer-sm .footer-item {
    display: flex;
    gap: .5rem;
    justify-content: flex-start;
    align-items: center;
  }

  .car-card-footer-sm .footer-item .bid-type {
    height: 30px;
  }

  .car-card-footer-sm .footer-item p {
    font-size: var(--fs-5);
    font-weight: bold;
  }

  .confirmation-drawer .notch {
    width: 3rem;
  }


  .header-flexed{
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start !important;
  }
  .header-right-aligned {
    justify-content: flex-start !important;
  }

  .header-flexed.select-winner{
    margin-top: 8rem;
  }
  .select-winner-header .top {
    justify-content: flex-start;
  }

  footer {
    height: 5rem !important;
  }

  footer a svg {
    font-size: 1.3rem;
  }
}